import React, { useState } from 'react'

import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import useDomReady from 'src/hooks/window/useDomReady'

import Hero from './sections/hero/_index'
import OsMelhoresGiftsCards from './sections/os-melhores-gifts-cards/_index'
import FazerAsContas from './sections/fazer-as-contas/_index'
import GiftCardInternacional from './sections/gift-card-internacional/_index'
import ComoComprarGiftCard from './sections/como-comprar-gift-card/_index'
import EscolhaOGiftCard from './sections/escolha-o-gift-card/_index'
import EconomizeAindaMais from './sections/economize-ainda-mais/_index'
import BlogTudoOQuePrecisaSaber from './sections/blog-tudo-o-que-precisa-saber/_index'
import Faq from './sections/Faq/_Faq'

import pageContext from './pageContext.json'

import AcesseAppGiftcard from './assets/images/qr-code-gift-card.png'

import { Wrapper } from './style'

const GitfCard = () => {
  const domReady = useDomReady()
  const [ isOpen, setIsOpen ] = useState(false)

  const openGiftcardFormModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Abra sua <span class="text-orange--extra">Global Account</span> e garanta os Gift Cards Internacionais'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para o SuperApp do Inter, ou caso não tenha instalado, para a loja de aplicativos. Aí é só baixar o app do Inter.'
        qrCode={AcesseAppGiftcard}
      />
    </Modal>
  )
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {openGiftcardFormModal}
        <Hero />
        <OsMelhoresGiftsCards setIsOpen={setIsOpen} />
        <FazerAsContas />
        <GiftCardInternacional setIsOpen={setIsOpen} />
        <ComoComprarGiftCard />
        <EscolhaOGiftCard />
        <EconomizeAindaMais />
        <BlogTudoOQuePrecisaSaber />
        <Faq faq={pageContext.structuredData.faq} />
      </Layout>
    </Wrapper>
  )
}

export default GitfCard
