import React from 'react'
import { widths } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'

import imgComoComprarGiftCard from '../../assets/images/imgComoComprarGiftCard.gif'

import { Circle, Section } from './style'

const ComoComprarGiftCard = () => {
  const width = useWidth(300)
  return (
    <Section id='como-comprar-gift-card'>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          {
            width < widths.md && (
              <div className='col-12 col-md-6 imagemBg'>
                <img src={imgComoComprarGiftCard} className='imagemGif mb-3' />
              </div>
            )
          }
          <div className='col-12 col-md-6'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-4 mt-5 mt-md-0'>
              Como comprar Gift Card
            </h2>
            <div className='d-flex align-items-center mb-3'>
              <div>
                <Circle>
                  1
                </Circle>
              </div>
              <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-grayscale--400 mb-0'>
                Acesse a área de Gift Cards no Super App
              </p>
            </div>
            <div className='d-flex align-items-center  mb-3'>
              <div>
                <Circle>
                  2
                </Circle>
              </div>
              <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-grayscale--400 mb-0'>
                Escolha o Gift Card e o valor que deseja comprar
              </p>
            </div>
            <div className='d-flex align-items-center'>
              <div>
                <Circle>
                  3
                </Circle>
              </div>
              <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-grayscale--400 mb-0'>
                Conclua a compra e use o saldo adquirido por meio do voucher gerado.
              </p>
            </div>
          </div>
          {
            width >= widths.md && (
              <div className='col-12 col-md-6 imagemBg'>
                <img src={imgComoComprarGiftCard} className='imagemGif mb-3' />
              </div>
            )
          }
        </div>
      </div>
    </Section>
  )
}

export default ComoComprarGiftCard
