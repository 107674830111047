import React, { ChangeEvent, useState } from 'react'
import { BoxSimulator } from './style'

const CashbackSimulator = () => {
  const [ newValue, setNewValue ] = useState<number>(200)
  const result = newValue * 0.475

  return (
    <BoxSimulator className='container py-3 pb-md-4 pt-md-5 mt-3 px-3'>
      <div className='px-3' />
      <div className='px-3 mt-3 mt-md-0 px-lg-0'>
        <p className='fs-14 fs-md-18 lh-17 lh-md-22 text-center text-grayscale--500 mb-lg-4'>
          Quanto você gasta em delivery por mês?
        </p>
        <p className='fs-24 lh-30 fw-600 text-center pt-md-3 pt-lg-0'>
          <span className='text-orange--extra fw-600'>R$ {newValue + ',00'}</span>
        </p>
        <span className='range-slider pb-md-3'>
          <input
            type='range'
            min={200}
            max={2200}
            value={newValue}
            step={400}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setNewValue((+e.target.value > 400) ? +e.target.value - 200 : +e.target.value)}
          />
          <span className='slider-container'>
            <span className='bar'>
              <span style={{ width: `${((newValue - 200) * 100) / 1800}%` }} />
            </span>
            <span className='bar-btn' style={{ left: `${((newValue - 200) * 100) / 1800}%` }} />
          </span>
        </span>
        <hr />
      </div>
      <div className='px-3 pt-2'>
        <p className='fs-14 fs-md-18 lh-16 lh-md-22 mb-0 text-grayscale--500 text-center mb-3'>
          Com o Gift Card do Inter você economiza cerca de:
        </p>
        <p className='fs-40 lh-50 text-center fw-600 pt-md-4 pt-lg-0 mb-2'>
          <span className='text-grayscale--500 text-center fw-600'>R$ {result + ',00'}</span>
        </p>
        <p className='fs-14 lh-16 cor text-center fw-600'>em cashback por ano</p>
      </div>
    </BoxSimulator>
  )
}

export default CashbackSimulator
