import styled from 'styled-components'
import { breakpoints, device } from 'src/styles/breakpoints'
import BgSectionMD from './../../assets/images/bg-hero-giftcard-md.png'
import BgSectionLG from './../../assets/images/bg-hero-giftcard-lg.png'
import BgSectionXL from './../../assets/images/bg-hero-giftcard-xl.png'
import { grayscale } from 'src/styles/colors'

export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    margin: 0 auto;
    width: 100%;
}
`
export const Section = styled.section`


  .btn {
    width: 100%;
  }

  @media ${device.tablet} {
    background: url(${BgSectionMD}) no-repeat;
    background-position:  right;
    height: 504px;
    background-size: contain ;
  }

  @media ${device.desktopLG} {
    background: url(${BgSectionLG}) no-repeat;
    background-position: top right;
    margin-top: 54px;
    height: 665px;
  }

  @media ${device.desktopXL} {
    background: url(${BgSectionXL}) no-repeat;
    background-position: top right;
    margin-top: 54px;
    height: 665px;
  }
  `
export const Input = styled.input`
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  border: none;

  &::placeholder {
    color: ${grayscale[500]} ;
  }

  @media (min-width: ${breakpoints.lg}){
    font-size: 32px;
    line-height: 40px ;
  }

  @media (min-width: ${breakpoints.xl}){
    font-size: 40px;
    line-height: 50px ;
  }
`
