import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'

import Savings from '@interco/icons/build-v4/orangeds/MD/savings'
import Credit from '@interco/icons/build-v4/orangeds/MD/credit'
import ListCheck from '@interco/icons/build-v4/orangeds/MD/list-check'
import { Section, Button } from './style'

type modalProps = {
  setIsOpen: Function;
}

const GiftCardInternacional = ({ setIsOpen }: modalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleClick = () => {
    setIsOpen(true)
  }

  return (
    <Section className='py-4'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 d-flex align-items-center'>
            <ImageWebp
              arrayNumbers={[ 312, 312, 400, 456, 456 ]}
              arrayNumbersHeight={[ 342, 342, 440, 500, 500 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/gift-card-internacional/image.webp'
              altDescription='Mulher usando chapéu e óculos escuros segurando sacolas de compras saindo da tela de um smartphone com várias marcas de gift cards inter.'
            />
          </div>
          <div className='col-12 col-md-6 my-4'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-grayscale--500'>
              Gift Card Internacional pra comprar nos EUA
            </h2>
            <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-grayscale--400'>
              O <strong>Gift Card Internacional</strong> é a melhor opção pra comprar nos Estados Unidos.
              E aqui no Inter você recebe cashback em dólar nas suas compras.
            </p>
            <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-grayscale--400 mb-4'>
              Abra a sua Global Account e aproveite um mundo de vantagens!
            </p>
            <div className='d-flex mb-4'>
              <Savings height={26} width={26} color='#161616' className='mr-3' />
              <div>
                <h3 className='fs-16 lh-20 fs-lg-20 lh-lg-25 text-grayscale--500 fw-600 mb-2'>Economia</h3>
                <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-grayscale--400 mb-0'>
                  Receba cashback em dólar direto na sua conta.
                </p>
              </div>
            </div>
            <div className='d-flex mb-4'>
              <div>
                <Credit height={26} width={26} color='#161616' className='mr-3' />
              </div>
              <div>
                <h3 className='fs-16 lh-20 fs-lg-20 lh-lg-25 text-grayscale--500 fw-600 mb-2'>Simplicidade</h3>
                <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-grayscale--400 mb-0'>
                  Não precisa comprar dólar ou estar com o cartão internacional. É só apresentar o voucher.
                </p>
              </div>
            </div>
            <div className='d-flex'>
              <div>
                <ListCheck height={26} width={26} color='#161616' className='mr-3' />
              </div>
              <div>
                <h3 className='fs-16 lh-20 fs-lg-20 lh-lg-25 text-grayscale--500 fw-600 mb-2'>Melhores marcas</h3>
                <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-grayscale--400 mb-0'>
                  Compre na Amazon, Best Buy, Apple, Disney e muito mais!
                </p>
              </div>
            </div>
            <Button
              onClick={() => {
                handleClick()
                sendDatalayerEvent({
                  section: 'dobra_4',
                  element_action: 'click button',
                  element_name: 'Ver lojas nos EUA',
                 })
              }}
            >
              Ver lojas nos EUA
            </Button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default GiftCardInternacional
