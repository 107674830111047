import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  .btn {
    @media ${device.tablet} {
      width: 360px;
    }
  }
`
