import styled from 'styled-components'
import { orange, white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section``

export const Button = styled.button`
  background: ${orange.extra};
  border: none;
  border-radius: 8px;
  width: 100%;
  height: 48px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: ${white};
  margin-top: 32px;

  @media (min-width: ${breakpoints.xl}){
    width: 360px;
  }

  &:focus {
    outline: none;
    color: ${white};
  }

`
