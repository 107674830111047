import styled from 'styled-components'
import { orange, white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  background: ${orange.extra} ;


  .react-multiple-carousel__arrow {
    svg {
      fill: ${white} !important;
    }
  }

  .react-multi-carousel-dot {
    button {
      background-color: ${white} !important;
    }
  }
`

export const Card = styled.div`
  width: 100%;
  height: 276px;
  border-radius: 8px;
  background: ${white} ;
  padding: 24px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;


  @media (min-width: ${breakpoints.xl}){
    height: 229px;
  }
`
