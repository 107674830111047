import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'

import BgSm from '../../assets/images/osMelhoresGiftsCards-sm.png'
import BgMd from '../../assets/images/osMelhoresGiftsCards-md.png'
import BgLg from '../../assets/images/osMelhoresGiftsCards-lg.png'
import BgXl from '../../assets/images/osMelhoresGiftsCards-xl.png'

type ImageProsp = {
  image: string;
}

export const Section = styled.section`
  background-image: url(${BgSm});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 607px;

  @media (min-width: ${breakpoints.md}){
    background-image: url(${BgMd});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 626px;
  }

  @media (min-width: ${breakpoints.lg}){
    background-image: url(${BgLg});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 680px;
  }

  @media (min-width: ${breakpoints.xl}){
    background-image: url(${BgXl});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 770px;
  }

  .react-multiple-carousel__arrow {
    svg {
      fill: ${orange.extra} !important;
    }
  }

  .react-multi-carousel-dot {
    button {
      background-color: ${orange.extra} !important;
    }
  }
`
export const Card = styled.div`
  height: 341px;
  width: 100%;
  border-radius: 8px;
  padding: 24px 12px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: end;
  background: url(${(props: ImageProsp) => props.image});
  background-repeat: no-repeat;
  background-size: 100%;

  @media (min-width: ${breakpoints.md}){
    height: 305px;
    padding: 24px 12px;
  }
  @media (min-width: ${breakpoints.lg}){
    height: 305px;
  }
  @media (min-width: ${breakpoints.xl}){
    width: 264px;
    height: 305px;
  }

`
export const Button = styled.a`
  background: ${white};
  border-radius: 8px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: ${orange.extra};
  border: 1px solid ${orange.extra};
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;


  &:focus, &:hover {
    outline: none;
    color: ${orange.extra};
  }
`

export const CardLink = styled.a`
 height: 341px;
  width: 100%;
  border-radius: 8px;
  padding: 24px 12px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: end;
  background: url(${(props: ImageProsp) => props.image});
  background-repeat: no-repeat;
  background-size: 100%;

  @media (min-width: ${breakpoints.md}){
    width: 216px;
    padding: 24px 12px;
    height: 305px;
  }
  @media (min-width: ${breakpoints.lg}){
    height: 305px;
  }
  @media (min-width: ${breakpoints.xl}){
    width: 264px;
    height: 305px;
  }
`
