import styled from 'styled-components'
import { grayscale, white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

import Bg from '../../assets/images/bgEscolhaComoComprar.png'

export const Section = styled.section`
  background: ${grayscale[100]};
  padding-top: 95px;
  padding-bottom: 40px;

  @media (min-width: ${breakpoints.md}){
    padding-bottom: 70px;
  }
  @media (min-width: ${breakpoints.lg}){
    padding-top: 120px;
    padding-bottom: 100px;
  }

  .imagemBg {
    background-image: url(${Bg});
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 312px;
    height: 312px;

    @media (min-width: ${breakpoints.lg}){
      width:  336px;
      height: 336px;
    }
    @media (min-width: ${breakpoints.xl}){
      width: 456px;
      height: 456px;
    }
  }


  .imagemGif{
    width: 210px;
    height: 407px;

    @media (min-width: ${breakpoints.md}){
      width: 223px;
      height: 415px;
    }
    @media (min-width: ${breakpoints.lg}){
      width: 237px;
      height: 455px;
    }
    @media (min-width: ${breakpoints.xl}){
      width: 304px;
      height: 571px;
    }
  }
`

export const Circle = styled.div`
  background: ${grayscale[500]};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: ${white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`
