export default [
  {
    image: require('../images/cardGooglePlay.png'),
    text: 'Use em jogos, apps e muito mais.',
    alt: 'Gift Card Google Play',
    modal: false,
    link: 'https://intergo.app/ef1d88a7',
  },
  {
    image: require('../images/cardRoblox.png'),
    text: 'Use em jogos, apps e muito mais.',
    alt: 'Gift Card Roblox',
    modal: false,
    link: 'https://intergo.app/a38cdb8d',
  },
  {
    image: require('../images/cardPlayStationStore.png'),
    text: 'Use em jogos, apps e muito mais.',
    alt: 'Gift Card Playstation Store',
    modal: false,
    link: 'https://intergo.app/0286678d',
  },
  {
    image: require('../images/cardUber.png'),
    text: 'Use em jogos, apps e muito mais.',
    alt: 'Gift Card Uber',
    modal: false,
    link: 'https://intergo.app/d743d2ef',
  },
  {
    image: require('../images/cardXbox.png'),
    text: 'Use em jogos, apps e muito mais.',
    alt: 'Gift Card Xbox',
    modal: false,
    link: 'https://intergo.app/d1c321a5',
  },
  {
    image: require('../images/cardNetflix.png'),
    text: 'Use em jogos, apps e muito mais.',
    alt: 'Gift Card Netflix',
    modal: false,
    link: 'https://intergo.app/1877d8ec',
  },
  {
    image: require('../images/cardAppleStore.png'),
    text: 'Use em jogos, apps e muito mais.',
    alt: 'Gift Card Apple',
    modal: false,
    link: 'https://intergo.app/6b2eeb27',
  },
  {
    image: require('../images/cardFreeFire.png'),
    text: 'Use em jogos, apps e muito mais.',
    alt: 'Gift Card Free Fire',
    modal: false,
    link: 'https://intergo.app/3dddfd34',
  },
  {
    image: require('../images/cardIfood.png'),
    text: 'Use em jogos, apps e muito mais.',
    alt: 'Gift Card ifood',
    modal: false,
    link: 'https://intergo.app/eb8735ca',
  },
  {
    image: require('../images/cardAmericanAirlines.png'),
    text: 'Use em jogos, apps e muito mais.',
    alt: 'Gift Card Internacional America Airlines',
    modal: true,
  },
  {
    image: require('../images/cardBestBay.png'),
    text: 'Use em jogos, apps e muito mais.',
    alt: 'Gift Card Best Buy',
    modal: true,
  },
  {
    image: require('../images/cardTarget.png'),
    text: 'Use em jogos, apps e muito mais.',
    alt: 'Gift Card Internacional Target',
    modal: true,
  },
  {
    image: require('../images/cardWalmart.png'),
    text: 'Use em jogos, apps e muito mais.',
    alt: 'Gift Card Internacional Walmart',
    modal: true,
  },
]
