import { grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
`

export const Title = styled.h3`
  display: block;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${grayscale[500]};

  @media ${device.desktopLG} {
    font-size: 32px;
    line-height: 40px;
  }

  @media ${device.desktopLG} {
    font-size: 40px;
    line-height: 50px;
  }
`
