import styled from 'styled-components'
import BgSm from '../../assets/images/escolhaOGiftCard-sm.png'
import BgMd from '../../assets/images/escolhaOGiftCard-md.png'
import BgLg from '../../assets/images/escolhaOGiftCard-lg.png'
import BgXl from '../../assets/images/escolhaOGiftCard-xl.png'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-image: url(${BgSm});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 360px;


  @media (min-width: ${breakpoints.md}){
    background-image: url(${BgMd});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 768px;
  }

  @media (min-width: ${breakpoints.lg}){
    background-image: url(${BgLg});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 788px;
  }

  @media (min-width: ${breakpoints.xl}){
    background-image: url(${BgXl});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 861px;
  }
`
export const Card = styled.a`
  width: 100%;
  height: 169px;
  background: #ffffff08;
  border-radius: 16px;
  box-shadow: 4px 4px  #FF7A0080;
  padding: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;



  &.vale-presente {
    display: flex;
    align-items: center;
  }

  .first-card {
    flex-direction: column;
    justify-content: space-between;
    display: block ;
  }

  img {
    max-width: 100%;
    right: 0;
    max-height: 144px;

    @media (min-width: ${breakpoints.xl}){
      bottom: 5px;
      position: inherit;
    }

  }

  .card-text {
    max-width: 126px;

    @media (min-width: ${breakpoints.xl}){
      max-width: 100%;
    }
  }


`
