import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// Data
import blogJSON from '../../assets/data/_blog'
import ArrowRight from '@interco/icons/build-v4/orangeds/XL/arrow-right'

// styles
import { Card } from './style'

// types
type blogProps = {
  image: string;
  tag: string;
  title: string;
  link: string;
}

const BlogTudoOQuePrecisaSaber = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row d-flex justify-content-center'>
          <div className='col-12'>
            <h2
              className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-5 text-center'
            >
              Tudo o que você precisa saber sobre Gifts Cards
            </h2>
          </div>
          {
            blogJSON.map((item: blogProps) => (
              <div className='col-12 col-md-4' key={item.title}>
                <Card
                  href={item.link}
                  target='_blank'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_8',
                      element_action: 'click button',
                      element_name: item.title,
                      redirect_url: item.link,
                     })
                  }}
                  rel='noreferrer'
                >
                  <img className='card-image' src={item.image} alt={item.title} />
                  <figcaption className='card-texts'>
                    <h3 className='fs-16 lh-20 fs-md-14 lh-md-17 text-grayscale--500 fw-600  mt-2 mb-2'>{item.title}</h3>
                    <div>
                      <ArrowRight height={24} width={24} color='#FF7A00' />
                    </div>
                  </figcaption>
                </Card>
              </div>
            ))
          }
          <div className='col-8 text-center mt-4'>
            <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 fw-400 text-grayscale--400 text-md-center'>
              <strong>Gift card</strong> é um tipo de cartão <strong>pré-pago</strong>, ou <strong>vale-compras</strong>, que pode ser utilizado como forma de pagamento em lojas físicas, virtuais e em aplicativos.
            </p>
            <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 fw-400 text-grayscale--400 text-md-center'>
              Além disso, os <strong>gift cards</strong> também podem ser usados para presentear. Sabe quando bate aquela dúvida do que dar de presente? Um <strong>vale presente</strong> pode ser a solução pra acertar na escolha.
            </p>
            <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 fw-400 text-grayscale--400 text-md-center'>
              E você ainda pode aproveitar o <strong>gift card internacional</strong> para <strong>comprar nos EUA!</strong> É só adquirir seu voucher e usá-lo para realizar o pagamento em lojas e até mesmo parques nos Estados Unidos. Demais, né?!
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlogTudoOQuePrecisaSaber
