export default [
  {
    image: require('../images/blog-1.png'),
    tag: 'Para simplificar a vida',
    title: 'Como comprar nos EUA usando Gift Card Internacional',
    link: 'https://blog.inter.co/como-comprar-nos-estados-unidos',
  },
  {
    image: require('../images/blog-2.png'),
    tag: 'Para simplificar a vida',
    title: 'Gift Card iFood: vantagens, onde comprar e como usar',
    link: 'https://blog.inter.co/gift-card-ifood',
  },
  {
    image: require('../images/blog-3.png'),
    tag: 'Para simplificar a vida',
    title: 'Quais os melhores jogos para Nintendo Switch?',
    link: 'https://blog.inter.co/jogos-de-nintendo-switch',
  },
]
