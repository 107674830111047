import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import cardJSON from '../../assets/data/_card'
import { widths } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'

import { Section, Card, CardLink } from './style'

type CardProps = {
  image: string;
  text: string;
  alt: string;
  modal: boolean;
  link?: string;
}

type modalProps = {
  setIsOpen: Function;
}

const OsMelhoresGiftsCards = ({ setIsOpen }: modalProps) => {
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()
  const handleClick = () => {
    setIsOpen(true)
  }

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row '>
          <div className='col-12 text-md-center'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-white'>
              <span>Os melhores <span className='text-orange--light'>Gift Cards</span> com a</span> economia do cashback
            </h2>
            <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-white mb-4'>
              <span className='d-block'>Pra você ou pra usar como <strong>cartão presente</strong>, garanta seu <strong>Gift Card online</strong> direto pelo</span> Super App e economize em suas compras.
            </p>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 3 }}
              lg={{ items: 4 }}
              xl={{ items: 4 }}
              itemClass='px-1'
              autoPlay
              infinite
            >
              {
                cardJSON.map((item: CardProps) => (
                  width < widths.md ? (
                    <CardLink
                      key={item.image}
                      image={item.image}
                      href={item.link}
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_2',
                          element_action: 'click button',
                          element_name: item.text,
                          redirect_url: item.link,
                         })
                      }}
                    />
                  ) : (
                    item.modal ? (
                      <Card
                        image={item.image}
                        onClick={() => {
                          handleClick()
                          sendDatalayerEvent({
                            section: 'dobra_2',
                            element_action: 'click button',
                            element_name: item.text,
                            redirect_url: item.link,
                           })
                        }}
                      />
                    ) : (
                      <CardLink
                        image={item.image}
                        href={item.link}
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_2',
                            element_action: 'click button',
                            element_name: item.text,
                            redirect_url: item.link,
                           })
                        }}
                      />
                    )
                  )
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default OsMelhoresGiftsCards
