import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const Card = styled.a`
  overflow: hidden;
  display: block;
  border-radius:  16px;

  .card-image {
    object-fit: cover;
    border-radius: 16px;
  }

  .card {
    &-texts {
      background-color: #fff;
      border-radius: 16px 16px 0px 0px;
      position: relative;
      margin-top: -42px;
      padding: 17px 23px;
      border: 1px solid  ${orange.extra};
      border-radius:  16px;
      width: 100%;
      height:  86px;
      display: flex;
      align-items: center ;
      margin-bottom: 30px;

      @media (min-width: ${breakpoints.lg}){
        width:  296px;
      }

      @media (min-width: ${breakpoints.xl}){
        width: 359px;
        height: 72px;
      }
    }

    &-tag {
      font-size: 12px;
      line-height: 15px;
      padding: 2px 4px;
      background: #FF7A00;
      border-radius: 4px;
      font-weight: 700;
      color: #fff;
    }

    &-description {
      @media(min-width: ${breakpoints.md}) {
        min-height: 95px;
      }
      @media(min-width: ${breakpoints.lg}) {
        min-height: 76px;
      }
      @media(min-width: ${breakpoints.xl}) {
        min-height: 57px;
      }
    }
  }
`
