import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import cardJSON from '../../assets/data/escolha-ainda-mais.json'
import { Section, Card } from './style'

type CardProps = {
  icon: string;
  title: string;
  paragraph: string;
  link: string;
}

const EconomizeAindaMais = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white fw-600 text-center'>
              Economize ainda mais no seu dia a dia
            </h2>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 3 }}
              lg={{ items: 4 }}
              xl={{ items: 4 }} itemClass='px-2'
              autoPlay
              infinite
            >
              {
                cardJSON.map((item: CardProps) => (
                  <Card key={item.paragraph}>
                    <div>
                      <OrangeDsIcon size='MD' icon={item.icon} color='#161616' className='mb-2' />
                    </div>
                    <h3 className='fs-20 lh-25 fs-xl-24 lh-xl-30 text-grayscale--500 fw-600 mb-2'>{item.title}</h3>
                    <p className='fs-16 lh-19 fs-xl-18 lh-xl-21 text-grayscale--500 fw-400 mb-2'>{item.paragraph}</p>
                    <a
                      href={item.link}
                      className='fs-18 lh-21 fw-700 text-orange--extra'
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_7',
                          element_action: 'click button',
                          element_name: 'Aproveitar',
                          redirect_url: item.link,
                         })
                      }}
                    >
                      Aproveitar
                    </a>
                  </Card>
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default EconomizeAindaMais
